export enum Actions {
  CATEGORY_FILTER = 'category_filter',
  CATEGORY_FILTER_SEARCH = 'category_filter_search',
  ACTIVITIES_SELECTED = 'activities_selected',
  PEBBLE_ICON = 'pebble_icon',
  MAP_SELECTED = 'map_selected',
  MAP_ACTIVITY_SELECTED = 'map_activity_selected',
  PREFERENCES_SELECTED = 'preferences_selected',
  PREFERENCES_FILTER = 'preferences_filter',
  CHILD_AGE_FILTER_SEARCH = 'child_age_filter_search',
  ENQUIRE_LISTINGS = 'enquire_listings',
  INSTANT_LISTINGS = 'instant_listings',
  MORE_DETAILS = 'more_detail',
  CARD_LISTINGS = 'card_listings',
  UPDATE_LOCATION = 'update_location',
  PAGINATION_NAVIGATION = 'pagination_navigation',
  SUPPLIER_SELECTED_DETAILS = 'supplier_selected_details',
  SHARE_DETAILS = 'share_details',
  READ_MORE_DETAILS = 'read_more_details',
  INSTANT_DETAILS = 'instant_details',
  TIME_DETAILS = 'time_details',
  DATE_DETAILS = 'date_details',
  VIEW_DATES_DETAILS = 'view_dates_details',
  BACK_DETAILS = 'back_details',
  USER_SEES_MORE_THAN_ONE_BOOKABLE_LISTING = 'user_sees_more_than_one_bookable_listing',
  GUEST_CHECKOUT_SELECTED = 'guest_checkout_selected',
  CREATE_AN_ACCOUNT = 'create_an_account_selected',
  MARKETPLACE_LOGIN_SELECTED = 'marketplace_login_selected',
  CHECKOUT_T_AND_C = 'checkout_t_and_c',
  GUEST_CHECKOUT_NAME = 'guest_checkout_name',
  GUEST_CHECKOUT_EMAIL = 'guest_checkout_email',
  GUEST_CHECKOUT_TELEPHONE = 'guest_checkout_telephone',
  GUEST_CHECKOUT_CHILD_NAME = 'guest_checkout_child_name',
  GUEST_CHECKOUT_CHILD_DOB = 'guest_checkout_child_dob',
  GUEST_CHECKOUT_PRIVACY_POLICY = 'guest_checkout_privacy_policy',
  GUEST_CHECKOUT_MARKETING = 'guest_checkout_marketing_optout',
  GUEST_CHECKOUT_PROCEED_TO_PAYMENT = 'guest_checkout_proceed_to_payment',
  GUEST_CHECKOUT_INITIATE = 'guest_checkout_initiate',
  CHECKOUT_INITIATE = 'checkout_initiate',
  CHECKOUT_DETAILS_BACK = 'checkout_details_back',
  GUEST_CHECKOUT_DETAILS_BACK = 'guest_checkout_details_back',
  ADULT_CHECKOUT = 'adult_checkout',
  MARKETPLACE_EXPLORE_MORE_ACTIVITIES = 'marketplace_explore_more_activities',
  MARKETPLACE_GO_TO_HOMEPAGE = 'marketplace_go_to_homepage',
  MARKETPLACE_CONFIRMATION_ACCOUNT_CREATE = 'marketplace_confirmation_account_create',
  PROCEED_TO_PAYMENT_LOGGED_IN = 'proceed_to_payment_logged_in',
  PROCEED_TO_PAYMENT_GUEST = 'proceed_to_payment_guest',
  ACTIVITY_PROMO_CODE_INVALID = 'activity_promo_code_invalid',
  ACTIVITY_PROMO_CODE_VALID = 'activity_promo_code_valid',
  AGE_RANGE_OK = 'age_range_ok',
  AGE_RANGE_CANCEL = 'age_range_cancel',
  AGE_RANGE_WARNING = 'age_range_warning',
  PROFILE_ICON = 'profile_icon',
  GET_TICKETS = 'get_tickets',
  TICKETS_CLOSE = 'tickets_close',
  TICKETS_BOOK_NOW = 'tickets_book_now',
  ADDITION_BASKET = 'addition_basket',
  MINUS_BASKET = 'minus_basket',
  BOOK_BASKET = 'book_basket',
  REQUEST_WAITLIST = 'request_waitlist',
  ACTIVITIES_NAV = 'activities_nav',
  AD_HOC_NAV = 'ad_hoc_nav',
  ACCOUNT_NAV = 'account_nav',
  SEARCH = 'activity_search',
  PAGE_VIEW = 'page_view',
  ADD_TO_CART = 'add_to_cart',
  WAITLIST_CTA = 'waitlist_CTA',
  SELECTED_DAY = 'selected_day',
  DATE_NOT_RUN = 'date_not_run',
  ADP_SUB_DETAILS = 'ADP_sub_details',
  SUB_SUBSCRIBE = 'sub_subscribe',
  TASTER_CHECKOUT = 'taster_checkout',

  ACTIVITIES_MENU = 'activities_menu',
  SEARCH_BAR_SELECT = 'search_bar_select',
  SEARCH_CLEAR_ALL = 'search_clear_all',
  ENTERS_POSTCODE = 'enters_postcode',
  SEARCH_LOCATION_SELECT = 'search_location_select',
  SEARCH_AGE_SELECT = 'search_age_select',
  SEARCH_WHEN_SELECT = 'search_when_select',
  SEARCH_ACTIVITY_SELECT = 'search_activity_select',
  GEOLOCATION_SELECT = 'geolocation_select',
  DISTANCE_SELECTED = 'distance_selected',
  FILTER_MIN_AGE_SELECTED = 'filter_min_age_selected',
  FILTER_MAX_AGE_SELECTED = 'filter_max_age_selected',
  DATE_SELECT_SCHEDULE = 'date_select_schedule',
  SEARCH_NEXT_MONTH = 'search_next_month',
  SEARCH_PREVIOUS_MONTH = 'search_previous_month',
  DAY_SELECT = 'day_select',
  SEARCH_TERM_ENTER = 'search_term_enter',
  SEARCH_TERM_DELETE = 'search_term_delete',
  SEARCH_DESKTOP_BUTTON = 'search_desktop_button',
  SEARCH_DESKTOP_UPDATE = 'search_desktop_update',
  SEARCH_SHOW_ACTIVITIES = 'search_show_activities',

  OPEN_FILTERS = 'open_filters',
  CATEGORY_CAROUSEL_ICON_SELECT = 'category_carousel_icon_select',
  FILTER_SHOW_ACTIVITIES = 'filter_show_activities',
  FILTER_RESET = 'filter_reset',
  FILTER_RESET_ALL = 'filter_reset_all',

  FILTER_BACK = 'filter_back',
  CATEGORY_BACK = 'category_back',
  TIME_BACK = 'time_back',
  PROVIDER_BACK = 'provider_back',
  CATEGORY_FILTER_SELECT = 'category_filter_select',
  FILTER_CATEGORY_SELECTED = 'filter_category_selected',
  PROVIDER_FILTER_SELECT = 'provider_filter_select',
  POSTCODE_SEARCH_SELECT = 'postcode_search_select',
  POSTCODE_SEARCH_ADD = 'postcode_search_add',
  TIME_FILTER_SELECT = 'time_filter_select',
  AM_SCHEDULE_SELECT = 'am_schedule_test',
  PM_SCHEDULE_SELECT = 'pm_schedule_test',
  ALL_DAYS_SCHEDULE_SELECT = 'all_days_schedule_test',

  PROVIDER_SEARCH_SELECT = 'provider_search_select',
  PROVIDER_SEARCH_ADD = 'provider_search_add',
  PROVIDER_SEARCH_CLEAR = 'provider_search_clear',
  PROVIDER_SELECTED = 'provider_selected',
  PROVIDER_SHOW = 'provider_show',
  PROVIDER_SELECT_ALL = 'provider_select_all',
  PROVIDER_CLEAR_ALL = 'provider_clear_all',
  LET_US_KNOW_PROVIDER = 'let_us_know_provider',

  INFO_CHILD_YES = 'info_child_yes',
  INFO_CHILD_NO = 'info_child_no',
  INFO_CHILD_ADDED = 'info_child_added',
  INFO_CHILD_CLOSE = 'info_child_close',
  PHOTO_CONSENT_YES = 'photo_consent_yes',
  PHOTO_CONSENT_NO = 'photo_consent_no',
  SUPPLIER_MARKETING_CONFIRMED = 'supplier_marketing_confirmed',

  INSTANT_BOOK_BANNER_SELECTED = 'instant_book_banner_selected',
  INSTANT_BOOK_BANNER_DESELECTED = 'instant_book_banner_deselected',
  INSTANT_BOOK_FILTER_INCLUDE = 'instant_book_filter_include',
  INSTANT_BOOK_FILTER_EXCLUDE = 'instant_book_filter_exclude',
  ONLINE_INCLUDE = 'online_include',
  ONLINE_EXCLUDE = 'online_exclude',

  BOOK_ON_PROVIDER_WEBSITE = 'book_on_provider_website',
  CALL_PROVIDER = 'call_provider',
  EMAIL_PROVIDER = 'email_provider',

  VERIFIED_PEBBLE = 'verified_pebble',
  PEBBLE_PROMISE_MORE = 'pebble_promise_more',

  SUPPLIER_IFRAME = 'supplier_iframe',
  ACTIVITY_IFRAME = 'activity_iframe',
  WIDGET_IFRAME = 'widget_iframe',

  CARD_IFRAME = 'card_iframe',
  INSTANT_IFRAME = 'instant_iframe',
  MORE_DETAILS_IFRAME = 'more_details_iframe',
  PAGINATION_IFRAME = 'pagination_iframe',
  ACTIVITY_BACK_IFRAME = 'activity_back_iframe',
  ACTIVITY_ADDRESS_IFRAME = 'activity_address_iframe',
  VERIFIED_PEBBLE_IFRAME = 'verified_pebble_iframe',
  ACTIVITY_SHARE_IFRAME = 'activity_share_iframe',
  ACTIVITY_MAP_IFRAME = 'activity_map_iframe',
  BLOCK_IFRAME = 'block_iframe',
  SINGLE_IFRAME = 'single_iframe',
  ACTIVITY_DATE_IFRAME = 'activity_date_iframe',
  ACTIVITY_TIME_IFRAME = 'activity_time_iframe',
  ACTIVITY_DAY_IFRAME = 'activity_day_iframe',
  VIEW_DATES_IFRAME = 'view_dates_iframe',
  GET_TICKETS_IFRAME = 'get_tickets_iframe',
  ADD_BASKET_IFRAME = 'add_basket_iframe',
  MINUS_BASKET_IFRAME = 'minus_basket_iframe',
  BOOK_TICKETS_IFRAME = 'book_tickets_iframe',
  SINGLE_SESSION_SELECT_IFRAME = 'single_session_select_iframe',
  SINGLE_SESSION_SELECT_REMOVE_IFRAME = 'single_session_select_remove_iframe',
  BASKET_EXPAND_IFRAME = 'basket_expand_iframe',
  BASKET_MORE_SESSIONS_IFRAME = 'basket_more_sessions_iframe',

  REVIEW_OPEN_CHECKOUT_MODAL = 'review_open_checkout_modal',
  REVIEW_EDIT_CHECKOUT = 'review_edit_checkout',
  REVIEW_COMMENT_CHECKOUT = 'review_comment_checkout',
  REVIEW_SUBMIT_CHECKOUT = 'review_submit_checkout',
  REVIEW_CLOSE_CHECKOUT = 'review_close_checkout',
  REVIEWS_STARS_CLICK_ADP = 'reviews_stars_click_ADP',
  NEGATIVE_REVIEW_EMAIL = 'negative_review_email',
  NEGATIVE_REVIEW_SUBMIT = 'negative_review_submit',

  FRANCHISE_ADDRESS = 'franchise_address',
  FRANCHISE_MORE_CLASSES = 'franchise_moreclasses',
  FRANCHISE_MAP = 'franchise_map',
  FRANCHISE_SUPPLIER = 'franchise_supplier',
  FRANCHISE_SOCIALS = 'franchise_socials',

  SUPPLIER_MINIMAL_ACTIVITY_CARD = 'supplier_minimal_activity_card',
  SUPPLIER_PAGE_VIEW_ACTIVITIES = 'supplier_page_view_activities',

  MAP_TOGGLE = 'map_toggle',
  LIST_TOGGLE = 'list_toggle',
  FILTER_LIST = 'filter_list',
  FILTER_MAP = 'filter_map',
  CLUSTER_CLICK = 'cluster_click',
  PIN_CLICK = 'pin_click',
  RESULTS_MAP_MOVE = 'results_map_move',
  SEARCH_THIS_AREA = 'search_this_area',
  MAP_ACTIVITY_SELECT = 'map_activity_select',
  MAP_DRAWER_CLOSE = 'map_drawer_close',
  STICKY_MAP_TOGGLE = 'sticky_map_toggle',

  SINGLE_SESSION_SELECT = 'single_session_select',
  SINGLE_SESSION_SELECT_REMOVE = 'single_session_select_remove',
  BASKET_EXPAND = 'basket_expand',
  BASKET_MORE_SESSIONS = 'basket_more_sessions',
  LEAVING_MODAL_BACK_ADP = 'leaving_modal_back_adp',
  LEAVING_MODAL_CONTINUE_ADP = 'leaving_modal_continue_adp',
  LEAVING_MODAL_BACK_PRE_CHECKOUT = 'leaving_modal_back_pre_checkout',
  LEAVING_MODAL_CONTINUE_PRE_CHECKOUT = 'leaving_modal_continue_pre_checkout',

  CHECKOUT_ERROR_PAGE = 'checkout_result_error',
  CHECKOUT_ERROR_CONTACT_SUPPORT = 'checkout_error_contact_support',

  PONCHOPAY = 'ponchopay',

  CONFIRM_ATTENDEES = 'confirm_attendees',
  ANSWER_ATTENDEE_QUESTIONS = 'answer_attendee_questions',
  PRE_CHECKOUT_SUBMIT_INFORMATION = 'pre_checkout_submit_information',
  CLEAR_ATTENDEE_DATA = 'clear_attendee_data',
  POST_CHECKOUT_SUBMIT_INFORMATION = 'post_checkout_submit_information',

  WAITLIST_NAME = 'waitlist_name',
  WAITLIST_EMAIL = 'waitlist_email',
  WAITLIST_NUMBER = 'waitlist_number',
  WAITLIST_ATTENDEE_ADD = 'waitlist_attendee_add',
  WAITLIST_ATTENDEE_MINUS = 'waitlist_attendee_minus',
  WAITLIST_JOIN = 'waitlist_join',
  WAITLIST_DONE = 'waitlist_done',

  WAITLIST_NAME_IFRAME = 'waitlist_name_iframe',
  WAITLIST_EMAIL_IFRAME = 'waitlist_email_iframe',
  WAITLIST_NUMBER_IFRAME = 'waitlist_number_iframe',
  WAITLIST_ATTENDEE_ADD_IFRAME = 'waitlist_attendee_add_iframe',
  WAITLIST_ATTENDEE_MINUS_IFRAME = 'waitlist_attendee_minus_iframe',
  WAITLIST_JOIN_IFRAME = 'waitlist_join_iframe',
  WAITLIST_DONE_IFRAME = 'waitlist_done_iframe',

  SUPPLIER_CAROUSEL = 'supplier_carousel',
  ADP_CAROUSEL = 'adp_carousel',
  ADP_CAROUSEL_IFRAME = 'adp_carousel_iframe',
  FRANCHISE_CAROUSEL = 'franchise_carousel',
  SUPPLIER_READ_MORE = 'supplier_read_more',
  SUPPLIER_MAP = 'supplier_map',
  SUPPLIER_ACCORDION_EXPAND = 'supplier_accordion_expand',
  SUPPLIER_ACCORDION_COLLAPSE = 'supplier_accordion_collapse',
  SUPPLIER_SOCIALS = 'supplier_socials',
  ADP_SOCIALS = 'adp_socials',
  ADP_SOCIALS_IFRAME = 'adp_socials_iframe',

  PROVIDER_CARD_CLICK = 'provider_card_click',
  SEE_CLASSES = 'see_classes',
  HIDE_CLASSES = 'hide_classes',
  PROVIDER_CARD_ACCORDION_COLLAPSE = 'provider_card_accordion_collapse',
  PROVIDER_CARD_ACCORDION_EXPAND = 'provider_card_accordion_expand',
  PROVIDER_CARD_ACTIVITY = 'provider_card_activity',
  SEE_MORE_FROM_PROVIDER = 'see_more_from_provider',

  WHEN_FILTER_ALL_DAYS = 'when_filter_all_days',
  WHEN_FILTER_WEEKDAYS = 'when_filter_weekdays',
  WHEN_FILTER_WEEKENDS = 'when_filter_weekends',

  STEPPER_ATTENDEES = 'stepper_attendees',
  STEPPER_ADD_ONS = 'stepper_add_ons',
  STEPPER_REVIEW_PAY = 'stepper_review_pay',
  CHECKOUT_NEXT_BOOKING_QUESTIONS = 'checkout_booking_questions',
  CHECKOUT_NEXT_ADDONS = 'checkout_next_addons',
  CHECKOUT_NEXT_REVIEWPAY = 'checkout_next_reviewpay',
  CHECKOUT_BACK_ACTIVITY_DETAILS = 'checkout_back_activity_details',
  CHECKOUT_BACK_ATTENDEES = 'checkout_back_attendees',
  CHECKOUT_BACK_ADDONS = 'checkout_back_addons',
  CHECKOUT_BACK_ANSWER_QUESTIONS = 'checkout_back_answer_questions',

  ADD_ADDON = 'add_addon',
  CANCEL_ADDON = 'cancel_addon',
  ADD_ADDON_TO_ORDER = 'add_addon_to_order',
  EDIT_ADDON = 'edit_addon',
  DELETE_ADDON = 'delete_addon',
  ADDON_CLEAR_MODAL_CLOSE = 'addon_clear_modal_close',
  ADDON_ORDER_NOTES = 'addon_order_notes',

  PAYMENT_MODAL_PONCHOPAY = 'payment_modal_ponchopay',
  PAYMENT_MODAL_CARD = 'payment_modal_card',

  SCHEDULE_ADP = 'schedule_ADP',
  SCHEDULE_ADP_SELECT = 'schedule_ADP_select',
  BASKET_EXPAND_V2 = 'basket_expand_v2',
  BASKET_COLLAPSE_V2 = 'basket_collapse_v2',
  BASKET_CHECKOUT = 'basket_checkout',
  ATTENDEE_DROPDOWN = 'attendee_dropdown',
  ATTENDEE_DROPDOWN_SELECT = 'attendee_dropdown_select',
  ATTENDEE_DROPDOWN_V2 = 'attendee_dropdown_v2',
  ATTENDEE_NAME_V2 = 'attendee_name_v2',
  ATTENDEE_DOB_V2 = 'attendee_dob_v2',
  ATTENDEE_IS_ADULT_V2 = 'attendee_is_adult_v2',
  ATTENDEE_CONFIRM_V2 = 'attendee_confirm_v2',
  ATTENDEE_CANCEL_V2 = 'attendee_cancel_v2',
  TERMS_NEW = 'terms_new',
  SUPPLIER_LINKS_ACCEPTED = 'supplier_links_accepted',
}
